<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
  .check(v-show='checkVisible')
    img.logo(alt="Vue logo" src="../assets/tomorrow-party-logo.png")
    h2.title 明日派對 調色盤
    h2.title Tomorrow Party
    h4.title-sub 藝術家 - 蔡宜婷
    //- el-card(shadow='hover')
      //- img.image(src='/img/qrcode.png' width='250')
      div(style='padding: 14px;')
        span 請戴上耳機並按下開始體驗
        .bottom.clearfix
      el-button.button(@click='playAudio') 開始體驗
    //- el-card(shadow='hover')
      //- img.image(src='/img/qrcode.png' width='250')
      div(style='padding: 14px;')
        div 互動人數：{{ socketData.online }}
        .bottom.clearfix

    el-card(shadow='hover')
      //- img.image(src='/img/qrcode.png' width='250')
      div(style='padding: 14px;')
        h3 改變明日派對動態圖形
        .bottom.clearfix
        //- .modeSelect
          el-radio-group(v-model='selectedMode' @change='setMode')
            el-radio.modeItem(label='mA') 演算A - Gray Scott
            el-radio.modeItem(label='mB') 演算B - Fitz-Hugh Nagumo
            el-radio.modeItem(label='mC') 演算C - Belousov Zhabotinsky
        el-slider.sl-1(v-model='sl_masterSwitch' :show-tooltip='false')
        el-slider.sl-2(v-model='sl_masterSpeed' :show-tooltip='false')
        el-slider.sl-3(v-model='sl_v3BeatSpeed' :show-tooltip='false')
        el-slider.sl-4(v-model='sl_v4Amp' :show-tooltip='false')
        el-slider.sl-5(v-model='sl_v5Sx' :show-tooltip='false')
        el-slider.sl-6(v-model='sl_v5Sy' :show-tooltip='false')
        el-slider.sl-7(v-model='sl_v6Expo' :show-tooltip='false')
        //- el-row
          el-col(:span='12')
            h4 底部顏色
            el-color-picker(v-model='color' show-alpha) 
          el-col(:span='12')
            h4 封包顏色
            el-color-picker(v-model='color2' show-alpha) 
        .bottom.clearfix
      el-button.button(@click='submit') Coloring
</template>

<script>
// @ is an alias to /src
// import * as Tone from 'tone'

export default {
  name: 'Home',
  components: {
    // Tone,
  },
  data() {
    return {
      socket: '',
      loading: false,
      checkVisible: true,
      color: 'rgba(30, 30, 30, 1)',
      color2: 'rgba(4, 13, 209, 1)',
      music: null,
      timeout: null,
      socketData: {
        online: 0,
        mode: 'mA',
        masterSwitch: 100,
        masterSpeed: 20,
        v3BeatSpeed: 37,
        v4Amp: 40,
        v5Sx: 100,
        v5Sy: 100,
        v6Expo: 40,
        cR: 30,
        cG: 30,
        cB: 30,
        pR: 4,
        pG: 13,
        pB: 209,
      },
      sl_masterSwitch: 100,
      sl_masterSpeed: 20,
      sl_v3BeatSpeed: 37,
      sl_v4Amp: 40,
      sl_v5Sx: 100,
      sl_v5Sy: 100,
      sl_v6Expo: 40,
    }
  },
  watch: {
    sl_masterSwitch() {
      console.log('watch :>> ', this.sl_masterSwitch);
      this.socket.emit('onMasterSwitch', this.sl_masterSwitch);
    },
    sl_masterSpeed(){
      this.socket.emit('onMasterSpeed', this.sl_masterSpeed);
    },
    sl_v3BeatSpeed() {
      
      this.socket.emit('onV3BeatSpeed', this.sl_v3BeatSpeed);
    },
    sl_v4Amp() {
      this.socket.emit('onV4Amp', this.sl_v4Amp);
    },
    sl_v5Sx() {
      this.socket.emit('onV5Sx', this.sl_v5Sx);
    },
    sl_v5Sy() {
      this.socket.emit('onV5Sy', this.sl_v5Sy);
    },
    sl_v6Expo() {
      this.socket.emit('onV6Expo', this.sl_v6Expo);
    },
  },
  mounted () {
    
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:6060', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://tomorrow-party.winnowork.com:6060', {
      withCredentials: true,
      extraHeaders: {
        'tomorrow-party-header': 'abcd',
      },
      query: {
        'client': 'mobile',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
      this.socket.emit('onReady');
    });

    this.socket.on('ready', (online) => {
      console.log('Got READY.', online);
      this.socketData.online = online;
      // this.startCheck = true;
    });

    this.socket.on('master_switch', (val) => {
      this.sl_masterSwitch = val;
    });
    this.socket.on('master_speed', (val) => {
      this.sl_masterSpeed = val;
    });
    this.socket.on('v3_beat_speed', (val) => {
      this.sl_v3BeatSpeed = val;
    });
    this.socket.on('v4_amp', (val) => {
      this.sl_v4Amp = val;
    });
    this.socket.on('v5_sx', (val) => {
      this.sl_v5Sx = val;
    });
    this.socket.on('v5_sy', (val) => {
      this.sl_v5Sy = val;
    });
    this.socket.on('v6_expo', (val) => {
      this.sl_v6Expo = val;
    });

  },
  destroyed() {
    this.socket.disconnect();
  },
  methods: {
    playAudio() {
      // const player = new Tone.Player('/audio/Re_Ge_1.wav').toDestination();
      // player.loop = true;
      // player.autostart = true;

      // this.music.src = '/audio/Re_Ge_1.wav';
      // this.music.loop = true;
      // this.music.play();
    },
    onChangeValue() {
      this.socketData.masterSwitch = this.sl_masterSwitch;
      this.socketData.masterSpeed = this.sl_masterSpeed;
      this.socketData.v3BeatSpeed = this.sl_v3BeatSpeed;
      this.socketData.v4Amp = this.sl_v4Amp;
      this.socketData.v5Sx = this.sl_v5Sx;
      this.socketData.v5Sy = this.sl_v5Sy;
      this.socketData.v6Expo = this.sl_v6Expo;
    },
    

    formatTooltip(val) {
      return val / 100;
    },
    formatSwitch(val) {
      return parseInt(val / 10);
    },
    submit() {
      // this.socketData.mode = this.selectedMode;
      // var ccc = 'rgba(19, 206, 102, 0.8)';
      // var c = this.color.split(/[(), ]/);
      // this.socketData.cR = c[1];
      // this.socketData.cG = c[3];
      // this.socketData.cB = c[5];

      // var c2 = this.color2.split(/[(), ]/);
      // this.socketData.pR = c2[1];
      // this.socketData.pG = c2[3];
      // this.socketData.pB = c2[5];
      this.onChangeValue();
      console.log(this.socketData);
      this.socket.emit('onUserSubmit', this.socketData);
    },
  },
}
</script>

<style lang='scss' scoped>
.container {
  // background-image: url('/img/bg.jpeg');
  background-color: rgb(29, 29, 29);
  height: auto;
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 100px;
  
  .logo {
    width: 300px;
  }

  .title {
    color: aliceblue;
    margin: 0px;
  }

  .title-sub{
    color: aliceblue;
    margin-bottom: 20px;
  }

  .check {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .el-card {
    margin-top: 20px;
    background-color: rgb(0 0 0 / 0%);
    border: 1px solid rgb(0 0 0 / 0%);
    color: white;
  }

  .last-card{
    margin-bottom: 50px;
  }

  .modeSelect {
    text-align: left;

    .modeItem {
      padding: 10px;
    }
  }

  .el-slider {
    margin-top: 10px;

    
  }

.sl-1{
  ::v-deep .el-slider__bar {
    background-color:rgb(205, 131, 173);
  }
}
.sl-2{
  ::v-deep .el-slider__bar {
    background-color:rgb(204, 150, 165);
  }
}

.sl-3{
  ::v-deep .el-slider__bar {
    background-color: rgb(209, 165, 160);
  }
}

.sl-4{
  ::v-deep .el-slider__bar {
    background-color: rgb(209, 224, 190);
  }
}
.sl-5{
  ::v-deep .el-slider__bar {
    background-color: rgb(210, 225, 191);
  }
}
.sl-6{
  ::v-deep .el-slider__bar {
    background-color: rgb(180, 213, 198)
  }
}
.sl-7{
  ::v-deep .el-slider__bar {
    background-color: rgb(119, 184, 188);
  }
}

  .tip-screen {
    margin-top: 20px;
    color: rgb(255, 98, 0);
  }

  .fade-enter-active, .fade-leave-active {
  transition: opacity .05s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>